import {
	components,
	MenuListProps,
	MenuProps,
	ControlProps,
	CSSObjectWithLabel,
	MultiValue,
	SingleValue,
	InputActionMeta,
} from 'react-select';
import { FieldError, RefCallBack } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { ISelectOptions } from 'types/utils';
import classes from '../Select/styles.module.scss';

const customStyles = {
	menuList: (provided: CSSObjectWithLabel) => ({
		...provided,
		paddingBottom: 0,
		paddingTop: 0,
	}),
	clearIndicator: () => ({
		marginTop: '4px',
	}),
	control: (provided: CSSObjectWithLabel) => ({
		...provided,
		borderStyle: 'hidden',
		borderBottom: '1.5px solid rgba(97,66,117,.1)',
		borderRadius: 0,
		':hover': {
			borderColor: 'rgba(97,66,117,.1)',
		},
		minHeight: 'auto',
		boxShadow: 'none',
	}),
	option: (provided: CSSObjectWithLabel, { isFocused }: { isFocused: boolean }) => ({
		...provided,
		backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.16)' : 'white',
		color: isFocused ? '#000 !important' : '#6d6e6f',
	}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		marginTop: 0,
	}),
};

const Menu = (props: MenuProps<ISelectOptions>) => {
	return <components.Menu {...props}>{props.children}</components.Menu>;
};

const MenuList = (props: MenuListProps<ISelectOptions>) => {
	return (
		<components.MenuList {...props} className={classes['select-items']}>
			{props.children}
		</components.MenuList>
	);
};

const LoadingIndicator = () => {
	return (<></>)
}

const Control = (props: ControlProps<ISelectOptions>) => {
	const { selectProps } = props;
	return (
		<components.Control
			{...props}
			className={`${classes['select-selected']} ${selectProps.menuIsOpen && classes['select-arrow-active']}`}
		>
			{props.children}
		</components.Control>
	);
};

const DropdownIndicator = () => {
	return null;
};

const IndicatorSeparator = () => {
	return null;
};

export function isSingleValue(
	item: MultiValue<ISelectOptions> | SingleValue<ISelectOptions>,
): item is SingleValue<ISelectOptions> {
	if (!item) {
		return false;
	} else if (Array.isArray(item)) {
		return false;
	}
	return true;
}

function filterOption(options: any, defaultValue: any) {
	let defaultOptions = [];
	if (Array.isArray(defaultValue)) {
		for (let i = 0; i < defaultValue.length; i++) {
			for (let j = 0; j < options.length; j++) {
				if (String(options[j]?.value) === String(defaultValue[i])) {
					defaultOptions.push(options[j]);
				}
			}
		}
	} else {
		defaultOptions = options.find((option: ISelectOptions) => String(option.value) === String(defaultValue));
	}
	return defaultOptions;
}

export default function CustomAsyncSelect({
	// options,
	onChange,
	placeholder = '',
	error,
	isClearable = false,
	label,
	defaultValue,
	isMulti = false,
	defaultOptions,
	load,
	innerRef,
	onInputChange,
	inputValue,
	width = 'auto'
}: {
	// options: ISelectOptions[];
	onChange: (option: string | null | number | (string | number)[]) => void;
	placeholder?: string;
	error?: FieldError | undefined;
	isClearable?: boolean;
	label?: string;
	defaultValue?: number | string;
	isMulti?: boolean;
	defaultOptions: ISelectOptions[];
	load: (inputValue: string, callback: (options: ISelectOptions[]) => void) => Promise<ISelectOptions[]>;
	innerRef?: undefined | RefCallBack;
	onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
	inputValue?: string;
	width?: string;
}) {
	const handleChange = (newValue: MultiValue<ISelectOptions> | SingleValue<ISelectOptions>): void => {
		if (!newValue) {
			onChange(newValue);
		} else if (Array.isArray(newValue)) {
			onChange(newValue.map((i: ISelectOptions) => i.value));
		} else if (isSingleValue(newValue)) {
			onChange(newValue.value);
		}
	};

	return (
		<label className={classes.label} style={{width: width}}>
			{label && <span className={classes['label__title']}>{label}</span>}
			<AsyncSelect
				styles={customStyles}
				defaultOptions={defaultOptions}
				className={`${classes['styled-select']} ${classes['styled-select--md']}`}
				loadOptions={load}
				// menuIsOpen
				isMulti={isMulti}
				ref={innerRef}
				isClearable={isClearable}
				onChange={handleChange}
				onInputChange={onInputChange}
				placeholder={placeholder}
				inputValue={inputValue}
				noOptionsMessage={() => <>Не найдено</>}
				loadingMessage={() => <>Загружается</>}
				components={{ MenuList, Menu, Control, DropdownIndicator, IndicatorSeparator, LoadingIndicator }}
				defaultValue={filterOption(defaultOptions, defaultValue)}
			/>
			{error && <small className={classes['label__error']}>{error.message}</small>}
		</label>
	);
}
