import moment from 'moment';
import React from 'react';
import { IUser } from 'types/users';
import styles from './styles.module.scss';
import { IOutsourcing } from 'types/outsourcing';

const date = new Date();

const OutsTable = ({data}:{data: IOutsourcing}) => (
  <table className={styles.table}>
    <tbody>
        
      <tr>
        <td>ФИО: {data?.user?.firstname} {data?.user?.secondname} {data?.user?.middlename}</td>
      </tr>
      <tr>
        <td>Должность: {data.position}</td>
      </tr>
      <tr>
        <td>Компания: {data?.counterparty?.title}</td>
      </tr>
      <tr>
        <td>{moment(date).format('DD.MM.YY HH:mm')}</td>
      </tr>
    </tbody>
  </table>
);

export default OutsTable;