import React, { Dispatch } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select, { ISelectOptions } from 'components/UI/Select';
import { OutsourceRolesEnum } from 'types/users';
import AsyncSelect from 'components/UI/AsyncSelect';
import { convertUser } from 'utils/helpers';
import useFetch from 'use-http';
import { ICounterparty } from 'types/counterparty';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

export const convertCounterparty = (data: ICounterparty[]) => {
	return data?.length ? data.map((item: ICounterparty) => {
		return {
			value: String(item.id),
			label: item.title,
		};
	}) : [];
};
interface IForm<T> {
	row: T;
	init: () => Promise<void>;
	update: (data: FormInputs) => Promise<void>;
	setDisplay: Dispatch<boolean>;
}

const positionOptions = [
	{
		value: OutsourceRolesEnum.Scrubwoman,
		label: OutsourceRolesEnum.Scrubwoman,
	},
	{
		value: OutsourceRolesEnum.Porter,
		label: OutsourceRolesEnum.Porter,
	},
	{
		value: OutsourceRolesEnum.Cashier,
		label: OutsourceRolesEnum.Cashier,
	},
	{
		value: OutsourceRolesEnum.Salesman,
		label: OutsourceRolesEnum.Salesman,
	}
]

export interface FormInputs {
	userId?: string;
	firstname: string;
	document: string;
	counterparty?: string;
	counterpartyId?: string;
	position: string;
}

const UserForm = ({onSubmit, defaultValues, mode = 'add'} : {onSubmit: SubmitHandler<FormInputs>, defaultValues?: FormInputs; mode?: string}) => {
	const { get, loading, error } = useFetch();
	const navigate = useNavigate();

	const [counterparty, setCounterparty] = React.useState('')
	const [initLoad, setInitLoad] = React.useState(false)

	const [counterpartyList, setCounterpartyList] = React.useState([]);
	const { handleSubmit, control, register, watch, setValue, formState, trigger, formState: { errors  }} = useForm<FormInputs>({ defaultValues });

	React.useEffect(() => {
		init();
	}, []);

	async function init() {
		const resultCounterparty = await get(`/api/counterparty`);
		setCounterpartyList(resultCounterparty);
		setInitLoad(true)
	}

	async function loadCounterparty(inputValue: string, callback: (options: ISelectOptions[]) => void) {
		let data = [];
		const params = new URLSearchParams({
			title: inputValue,
		});
		if (inputValue) {
			data = await get(`/api/counterparty?${params.toString()}`);
		}

		return convertCounterparty(data)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<div className="row">
				<div className="column-xs-12">
						<Controller
							name={`firstname`}
							control={control}
							rules={{ required: 'Обязательное поле' }}
							render={({ field, fieldState }) => {
								return (
									<Input
										onChange={field.onChange}
										label='введите ФИО'
										onBlur={field.onBlur}
										name={field.name}
										error={fieldState.error}
										defaultValue={field.value}
										disabled={mode ===  'edit' ? true : false}
									/>
								)
							}}
						/>
				</div>
				<div className="column-xs-12">
					{!watch('userId') && <Controller
						name={`document`}
						control={control}
						rules={{ required: 'Обязательное поле' }}
						render={({ field, fieldState }) => (
							<Input
								onChange={field.onChange}
								onBlur={field.onBlur}
								label='введите номер удостоверения'
								name={field.name}
								error={fieldState.error}
								defaultValue={field.value}
								disabled={mode ===  'edit' ? true : false}
							/>
						)}
					/>}
				</div>
				<div className="column-xs-12">
					{initLoad && <Controller
						name={`counterpartyId`}
						control={control}
						rules={{ required: 'Обязательное поле' }}
						render={({ field, fieldState }) => {
							return (
								<AsyncSelect 
									onChange={(v:any) => {
										setCounterparty('')
										setValue('counterparty', '')
										field.onChange(v)
									}}
									onInputChange={(inputValue, actionMeta) => {
										if (actionMeta.action === 'input-change') {
											setCounterparty(inputValue)
										}
									}}
									innerRef={field.ref}
									load={loadCounterparty}
									error={fieldState.error}
									label="введите контрагента"
									inputValue={counterparty}
									isClearable
									defaultOptions={convertCounterparty(counterpartyList)}
									defaultValue={field.value}
								/>
							)
						}}
					/>}
				</div>
				<div className="column-xs-12">
					<Controller
						name={`position`}
						control={control}
						rules={{ required: 'Обязательное поле' }}
						render={({ field, fieldState }) => {
							return (
								<Select
									onChange={field.onChange}
									options={positionOptions}
									label='введите должность'
									error={fieldState.error}
									defaultValue={field.value}
								/>
							)
						}}
					/>
				</div>
				<div className="column-xs-12">
					<div className="row">
						<div className="column-xs-6">
							<Button variant="violet" onClick={() => navigate('/qrcode')} style={{margin:'auto'}}>
								Назад
							</Button>
						</div>
						<div className="column-xs-6">
							<Button type="submit" style={{margin:'auto'}}>
								Подтвердить
							</Button>
						</div>						
					</div>
				</div>
			</div>
		</form>
	);
};

export default UserForm;