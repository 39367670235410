// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap--Lfv1B{height:45%;margin:0 auto;width:90%}.wrap--Lfv1B img{margin:auto}.form--PL75p{width:90%}.section-list--XjDyw{display:flex;flex-direction:column;gap:10px;justify-content:center;align-items:center;margin:30px 0;width:100%}.section-item--mP3o1{display:flex;width:100%;padding:0 20px;flex-direction:row;justify-content:space-around}.section-item-btn--fIATG{display:flex}.table--s5bur{border-collapse:collapse;margin:auto}.table--s5bur th,.table--s5bur td{border:1px solid #ddd;padding:8px;text-align:left}", "",{"version":3,"sources":["webpack://./src/pages/Outsourcing/styles.module.scss"],"names":[],"mappings":"AAAA,aACI,UAAA,CACA,aAAA,CACA,SAAA,CACA,iBACI,WAAA,CAGR,aACI,SAAA,CAGA,qBACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CACA,UAAA,CAEJ,qBACI,YAAA,CACA,UAAA,CACA,cAAA,CACA,kBAAA,CACA,4BAAA,CACA,yBACI,YAAA,CAKZ,cACI,wBAAA,CACA,WAAA,CAGJ,kCAEI,qBAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".wrap {\n    height: 45%;\n    margin: 0 auto;\n    width: calc(100% - 10%);\n    img {\n        margin: auto;\n    }\n}\n.form {\n    width: calc(100% - 10%);\n}\n.section {\n    &-list {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n        justify-content: center;\n        align-items: center;\n        margin: 30px 0;\n        width: 100%;\n    }\n    &-item {\n        display: flex;\n        width: 100%;\n        padding: 0 20px;\n        flex-direction: row;\n        justify-content: space-around;        \n        &-btn {\n            display: flex;\n        }\n    }\n}\n\n.table {    \n    border-collapse: collapse;\n    margin: auto;\n}\n\n.table th,\n.table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap--Lfv1B",
	"form": "form--PL75p",
	"section-list": "section-list--XjDyw",
	"section-item": "section-item--mP3o1",
	"section-item-btn": "section-item-btn--fIATG",
	"table": "table--s5bur"
};
export default ___CSS_LOADER_EXPORT___;
