import { KeycloakProfile } from 'keycloak-js';

export enum RolesEnum {
	Admin = 'Admin',
	Store = 'Store',
}

export enum OutsourceRolesEnum {
	Scrubwoman = 'Уборщица',
	Porter = 'Грузчик',
	Cashier = 'Кассир-аутсорсер',
	Salesman = 'Продавец'
}

export enum GroupsEnum {
	CLIENT = "/smena/smena. общее",
	ADMIN = "/smena/smena. администрирование",
	GEN_QRCODE = "/smena/smena. генерация qr-кода",
	BREACH_STAT = "/smena/smena. отчет по нарушениям присутствия в магазинах",
	MANUAL_REG = "/smena/smena. ручная регистрация сотрудника в журнале",
	SESSION_STAT = "/smena/smena. отчет статистики присутствия",
	OUTSOURCE = "/smena/smena. ручная регистрация аутсорсера в журнале",
}

export const RolesConst = {
	Admin: 'Admin',
};

export const getRole = (role: string): string => {
	switch (role) {
		case RolesEnum.Admin:
			return 'Admin';
		default:
			return 'Неизвестная роль';
	}
};

export enum PositionType {
	Office = 'office', // офис
	Market = 'market', // магазин
}

export interface IPosition {
	createdAt: Date;
	id: string;
	title: string;
	type: PositionType;
	unitId: string;
	updatedAt: Date;
}

export interface IUser {
	id: string;
	firstname: string;
	secondname: string;
	middlename: string;
	createdAt: Date;
	updatedAt: Date;
	position: IPosition;
	document?: string;
	outsourcePosition?: string;
	counterparty?: string;
}

export interface CustomKCProfile extends KeycloakProfile {
	attributes?: {
		num: string[];
		phoneNumber: string[];
		worklist: string[];
	}
}
