import React from 'react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { $store } from 'models/store';
import { useUnit } from 'effector-react';
import moment from 'moment';
import { ISession } from 'types/session';
import Table from 'components/UI/Table';
import usePaginate from 'hooks/usePaginate';
import Paginate from 'components/UI/Paginate';
import cn from "classnames";

const perPage = 15;

export default function Outsourcing() {
	const navigate = useNavigate();

    const store = useUnit($store);
    const { get } = useFetch();
    const [sessions, setSessions] = React.useState([])

    const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);


	const loadSession = React.useCallback(async () => {
        if (store) {
            const sessionList = await get(`api/session/findOutsourcing?offset=${offset}&limit=${perPage}&storeId=${store.id}`);
            if (sessionList?.rows?.length) {
                setSessions(sessionList.rows)
                setCount(sessionList.count)
            }
        }
        
	}, [store, offset]);

    React.useEffect(() => {
        loadSession()
    }, [store, offset, perPage])

    const headers = [
        {
            value: 'beginId',
            title: 'Время прихода',
            width: '100px',
            formatted: React.useCallback((row: any) => {
                return (
                    <div>
                        {row.begin && moment(row.begin.createdAt).format('DD.MM HH:mm')}
                    </div>
                );
            }, []),
        },
        {
            value: 'endId',
            title: 'Время ухода',
            width: '100px',
            formatted: React.useCallback((row: ISession) => {
                return (
                    <div>
                        {row.end && moment(row.end.createdAt).format('DD.MM HH:mm')}
                    </div>
                );
            }, []),
        },
        {
            value: 'outsourcePosition',
            title: 'Должность',
            width: '100px',
            formatted: React.useCallback((row: any) => {
                return (
                    <div>
                        {row?.begin?.outsourcing?.position}
                    </div>
                );
            }, []),
        }, 
        {
            value: 'fio',
            title: 'ФИО',
            width: '300px',
            formatted: React.useCallback((row: any) => {
                return (
                    <div>
                        <span>{row.user?.firstname} </span>
                        <span>{row.user?.secondname} </span>
                        <span>{row.user?.middlename} </span>
                    </div>
                );
            }, []),
        },
        {
            value: 'counterparty',
            title: 'Компания',
            formatted: React.useCallback((row: any) => {
                return <div>{row?.begin?.outsourcing?.counterparty?.title}</div>;
            }, []),
        },
        {
            value: 'document',
            title: 'Номер док-та',
            formatted: React.useCallback((row: any) => {
                return <div>{row?.document}</div>;
            }, []),
        },
        {
            value: 'status',
            title: 'Статус смены',
            formatted: React.useCallback((row: any) => {
                return <div>{row?.endId ? 'Закрыта': 'Открыта'}</div>;
            }, []),
        },
        {
            value: 'operation',
            title: 'Операции',
            formatted: React.useCallback((row: any) => {
                return <div>
                    {!row.endId &&  row?.begin && (
                        <>
                            <Button variant="green" onClick={() => navigate(`accept/${row.begin.outsourcing?.id}/close`)}>
                                Закрыть смену
                            </Button>
                        </>
                    )}
                </div>;
            }, []),
        }
    ]

	return (
		<div className={styles["section-list"]}>
			<h2>Открытые смены</h2>

            <div className={cn(styles["section-item"], styles["section-item-top"])}>
                <Button variant="violet" onClick={() => navigate('add')}>
                    Добавить сотрудника аутсорс
                </Button> 
            </div>
            <div className={styles["section-item"]}>
                <Table headers={headers} rows={sessions} />                
            </div>
            <div className={styles["section-item"]}>
                {pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
            </div>
            <div className={styles["section-item"]}>
            </div>
		</div>
	);
}
