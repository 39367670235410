// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/Oval Copy 2.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./img/Oval.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./img/Oval Copy.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".border-top--flkyw{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;height:100%;background-position-x:51px}.border-left--lUvhk{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;height:100%}.border-bottom--Qlb4j{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat bottom right;height:100%}", "",{"version":3,"sources":["webpack://./src/components/Border/styles.module.scss"],"names":[],"mappings":"AAAA,mBACI,4DAAA,CACA,WAAA,CACA,0BAAA,CAEJ,oBACI,4DAAA,CACA,WAAA,CAEJ,sBACI,yEAAA,CACA,WAAA","sourcesContent":[".border-top {\n    background: url('./img/Oval Copy 2.png') no-repeat;\n    height: 100%;\n    background-position-x: 51px;\n}\n.border-left {\n    background: url('./img/Oval.png') no-repeat;\n    height: 100%;\n}\n.border-bottom {\n    background: url('./img/Oval Copy.png') no-repeat bottom right;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border-top": "border-top--flkyw",
	"border-left": "border-left--lUvhk",
	"border-bottom": "border-bottom--Qlb4j"
};
export default ___CSS_LOADER_EXPORT___;
